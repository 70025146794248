import _ from 'lodash'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Backdrop,
  Badge,
  Button,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { CustomIcon, IconWrapper } from '../icon'
import Colors from '../../utils/colors'
import Divider from '../divider'
import Row from '../row'
import SocialLinks from '../social_links'
import TitledBox from '../titled_box'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    justifyContent: 'center',
  },
  organizationTileText: {
    textTransform: 'none',
    '& span': {
      whiteSpace: 'nowrap',
    },
    '& p': {
      whiteSpace: 'nowrap',
    },
  },
  fields: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
  },
  verified: {
    color: Colors.get('green', 700),
    padding: theme.spacing(1),
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  field: {
    color: Colors.get('grey', 700),
    display: 'flex',
    flexFlow: 'row',
    fontSize: 'small',
    margin: theme.spacing(0.5),
    maxWidth: 300,
    paddingLeft: theme.spacing(1),
    overflow: 'hidden',
  },
  fieldLabel: {
    marginRight: theme.spacing(1),
    minWidth: 100,
  },
  links: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}))

export default function OrganizationPopover({
  className,
  includeLabel,
  organization,
}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openPopover = event => {
    setAnchorEl(event.currentTarget)
  }
  const closePopover = () => {
    setAnchorEl(null)
  }

  return (
    <Grid container className={className}>
      <Row className={classes.container}>
        <Row justify={includeLabel ? 'center' : null} ignoreDefaultStyle={true}>
          {organization ? (
            <TitledBox
              title={includeLabel ? 'All Proceeds Go To:' : null}
              ignoreStyle={true}
            >
              <Button onClick={openPopover}>
                <ListItem ContainerComponent="div">
                  <ListItemAvatar>
                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      badgeContent={
                        <CustomIcon
                          icon={new IconWrapper('verified')}
                          color="green"
                        />
                      }
                    >
                      <Avatar
                        src={_.get(organization, ['info', 'logo'])}
                        alt={organization.name}
                      />
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={organization.name}
                    secondary="Verified 501(c)(3) Non-profit"
                    className={classes.organizationTileText}
                  />
                  <ListItemSecondaryAction>
                    <CustomIcon
                      icon={new IconWrapper('help')}
                      color={Colors.get('blueGrey', 300)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </Button>
            </TitledBox>
          ) : (
            <Skeleton variant="text" width={200} />
          )}
        </Row>
        <Backdrop className={classes.backdrop} open={anchorEl !== null}>
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Row justify="center">
              {organization ? (
                <Grid className={classes.fields}>
                  {organization.isNonprofit ? (
                    <Typography align="center" className={classes.verified}>
                      <CustomIcon
                        icon={new IconWrapper('verified')}
                        color="green"
                      />
                      Verified 501(c)(3) Non-profit
                    </Typography>
                  ) : null}
                  <Grid className={classes.field}>
                    <Grid className={classes.fieldLabel}>EIN</Grid>
                    <Grid>{organization.EIN}</Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.field}>
                    <Grid className={classes.fieldLabel}>Website</Grid>
                    <Grid>
                      <a
                        href={organization.info.website}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {organization.info.website}
                      </a>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.field}>
                    <Grid className={classes.fieldLabel}>Founded</Grid>
                    <Grid>{organization.foundedText}</Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.field}>
                    <Grid className={classes.fieldLabel}>Location</Grid>
                    <Grid>{organization.location}</Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.field}>
                    <Grid className={classes.fieldLabel}>Mission</Grid>
                    <Grid>{organization.mission}</Grid>
                  </Grid>
                  <Row justify="center">
                    <SocialLinks
                      links={organization.info}
                      className={classes.links}
                    />
                  </Row>
                </Grid>
              ) : null}
            </Row>
          </Popover>
        </Backdrop>
      </Row>
    </Grid>
  )
}
