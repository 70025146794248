import React from 'react'
import { Divider } from '@material-ui/core'

import Colors from '../utils/colors'

export default function CustomDivider({ className }) {
  return (
    <Divider
      className={className}
      style={{
        backgroundColor: 'initial',
        borderStyle: 'dashed',
        borderColor: Colors.alpha('grey', 0.24, 700),
        borderWidth: '0px 0px thin',
        width: '100%',
      }}
    />
  )
}
