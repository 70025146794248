import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Collapse,
  IconButton,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Skeleton } from '@material-ui/lab'

import { formatNumber } from '../utils/core'
import Chip from '../components/chip'
import Organization from '../backend/organization'
import OrganizationPopover from '../components/campaign/organization_popover'
import Page from './page'
import REST from '../utils/rest'
import { IconWrapper } from '../components/icon'
import Colors from '../utils/colors'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'hidden',
    },
  },
  headerRow: {
    '& > *': {
      fontWeight: 'bold',
      borderBottom: 'unset',
    },
  },
  pending: {
    fontStyle: 'italic',
    color: Colors.get('grey'),
  },
  orgAdjustment: {
    paddingLeft: 'unset',
    fontFamily: 'Karla',
  },
  payouts: {
    borderBottom: 'hidden',
    borderTop: 'groove',
  },
})

function Row({ campaign }) {
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()

  const organization = new Organization(campaign.organization)

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.orgAdjustment}>
          <OrganizationPopover
            organization={organization}
            includeLabel={false}
          />
        </TableCell>
        <TableCell align="right" className={classes.root}>
          {formatNumber(campaign.proceeds, {
            includeDecimal: true,
            isCurrency: true,
          })}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="button" gutterBottom component="div">
                Payouts
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow className={classes.headerRow}>
                    <TableCell>Date</TableCell>
                    <TableCell>Receipt</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(campaign.payouts, (payout, idx) => {
                    return (
                      <TableRow key={idx} className={classes.payouts}>
                        <TableCell>{payout.date}</TableCell>
                        <TableCell>
                          <Chip
                            icon={new IconWrapper('receipt')}
                            label="View receipt"
                            link={payout.receipt}
                          />
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(payout.amount, {
                            includeDecimal: true,
                            isCurrency: true,
                          })}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {campaign.remaining ? (
                    <TableRow>
                      <TableCell className={classes.pending}>Pending</TableCell>
                      <TableCell />
                      <TableCell align="right">
                        {formatNumber(campaign.remaining, {
                          includeDecimal: true,
                          isCurrency: true,
                        })}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

Row.propTypes = {
  campaign: PropTypes.shape({
    ID: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    organization: PropTypes.object.isRequired,
    payouts: PropTypes.array.isRequired,
    proceeds: PropTypes.number.isRequired,
    remaining: PropTypes.number.isRequired,
  }).isRequired,
}

const useStyles = makeStyles({
  title: {
    color: '#000000',
    fontFamily: 'Rubik',
    fontWeight: 'bold',
    fontSize: 'xx-large',
    marginBottom: '.5em',
    textAlign: 'center',
    //textShadow: '0 0 30px #9D9D9D',
  },
  subtext: {
    color: '#000000',
    fontWeight: '50',
    fontSize: 'large',
    marginBottom: '2em',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    maxWidth: '600px',
    textAlign: 'center',
    fontFamily: 'Karla',
  },
  headerRow: {
    '& > *': {
      fontWeight: 'bold',
      borderBottom: 'groove',
    },
  },
  container: {
    width: 'fit-content%',
    padding: '1.5em',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',

    backgroundImage: 'url("https://i.imgur.com/QNE9TO7.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
  },
  table: {
    width: 'fit-content',
    maxWidth: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'center',
    overflowX: 'scroll',
    boxShadow: '0 0 85px 2px rgba(228, 105, 105, 0.2)',
  },
  orgAdjustment2: {
    paddingLeft: '2em',
    fontFamily: 'Rubik',
  },
  amountRaised: {
    align: 'right',
    fontFamily: 'Rubik',
  },
  text: {
    alignSelf: 'center',
  },
  root: {
    padding: '1.5em',
    borderBottom: 'hidden',
  },
})

function Payouts() {
  const classes = useStyles()

  const [campaigns, setCampaigns] = React.useState([])

  React.useEffect(() => {
    REST.post('campaigns/payouts').then(setCampaigns)
  }, [])

  function content() {
    return (
      <Grid container className={classes.container}>
        <Grid className={classes.text}>
          <Typography className={classes.title}>PAYOUTS</Typography>
          <Typography className={classes.subtext}>
            Being transparent and earning your trust is important to us. That's
            why we show receipts for all proceeds we donate to our
            beneficiaries.
          </Typography>
        </Grid>
        <Grid className={classes.table}>
          <TableContainer component={Paper} className={classes.root}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className={classes.headerRow}>
                  <TableCell />
                  <TableCell className={classes.orgAdjustment2}>
                    Benefitting
                  </TableCell>
                  <TableCell className={classes.amountRaised}>
                    Amount Raised
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.root}>
                {campaigns.length
                  ? _.map(campaigns, (campaign, idx) => (
                      <Row key={idx} campaign={campaign} />
                    ))
                  : _.map(_.range(2), idx => (
                      <TableRow key={idx} className={classes.headerRow}>
                        <TableCell />

                        <TableCell>
                          <Skeleton variant="text" />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton variant="text" />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    )
  }

  return <Page content={content()} />
}

export default withSnackbar(Payouts)
